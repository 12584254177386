import { useEffect } from "react";


const details = [
    {
        num: '1.',
        title: 'Manager of Collected Data',
        details: 'Email: cabanero.jerryvel@gmail.com'
    },
    {
        num: '1.1.',
        title: 'Applicable Legislation',
        details: 'Our Privacy Policy is designed in accordance with the EU General Data Protection Regulation (GDPR) 2016/679 of the European Parliament and the Council of 27 April 2016, as well as other relevant legislation. By providing your data, you acknowledge that you have read and understood this Privacy Policy, giving your express consent to the processing of your personal data in accordance with the purposes and terms outlined herein. This Privacy Policy may be updated to reflect new laws, regulations, or interpretations.'
    },
    {
        num: '1.2.',
        title: 'Contact',
        details: 'Email: For any inquiries regarding the processing of your personal data, please contact us at cabanero.jerryvel@gmail.com.'
    },
    {
        num: '2.',
        title: 'Purpose of Obtaining and Processing Your Data',
        details: 'Your personal data is collected for the purpose of managing inquiries submitted through the contact section of my website. The data collected includes your name, email address, and the content of your inquiry.'
    },
    {
        num: '2.1.',
        title: 'Retention Period of Your Data',
        details: 'We will retain your personal data for as long as you consent to its processing. If you revoke your consent or request the restriction of processing, your data will be blocked and retained only for the legally required periods.'
    },
    {
        num: '3.',
        title: 'Legitimation and Data Collected',
        details: 'The processing of your data is based on the express consent you provide by completing the relevant form and ticking the acceptance box of this Privacy Policy.'
    },
    {
        num: '3.1.',
        title: 'Consent to Process Your Data',
        details: 'By submitting forms, and clicking to send data, or by sending emails through the accounts provided for this purpose, you confirm that you have read and accepted this Privacy Policy, and you give your explicit consent to the processing of your personal data in accordance with the purposes outlined.'
    },
    {
        num: '3.2.',
        title: 'Category of Data',
        details: 'The data collected are personal data and include your name, email address, and the content of your inquiry, provided by you through the contact form.'
    },
    {
        num: '4.',
        title: 'Security Measures',
        details: 'In order to ensure the security and confidentiality of your personal data, we have implemented appropriate technical and organizational measures to protect your data from unauthorized access, alteration, loss, or processing, in accordance with Article 32 of the GDPR EU 679/2016.'
    },
    {
        num: '5.',
        title: 'Transfer of Data',
        details: 'There is no planned transfer or international transfer of your data, except in cases required by tax, commercial, and telecommunications legislation, or when ordered by a judicial authority.'
    },
    {
        num: '6.',
        title: 'User Rights',
        details: 'You have the right to confirm whether we are processing your personal data, and you have the right to access your personal data. You may also request the rectification of inaccurate data, or request the deletion of your data when it is no longer needed for the purposes for which it was collected. Under certain circumstances, you may request that we restrict the processing of your data. You may also object to the processing of your data due to your specific situation. In these cases, we will stop processing your data unless we have compelling legitimate grounds or need to defend against potential claims.'
    },
    {
        num: '6.1.',
        title: 'How to Exercise Your Rights',
        details: 'To exercise your rights, please contact us at cabanero.jerryvel@gmail.com. Remember to include a copy of a document that allows us to verify your identity.'
    },
]


export default function ProvicyPolicy() {

    useEffect(() => {
        document.title = 'PRIVACY POLICY __JERRYVEL';
    }, []);

    return (
        <div className="w-4/5 md:ml-[5%] m-auto mobile:mt-40 mobile:mb-5 md:mt-5 md:mb-5 animate-fadeInTop">
            <div className="h-auto flex flex-col">
                <h2 className="text-bigL mobile:text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-bold">PRIVACY POLICY</h2>
                {details.map((info, key) => {
                    return(
                        <div key={key} className="flex flex-col gap-3 mt-5">
                            <div className="flex flex-row gap-2">
                                <p>{info.num}</p>
                                <h3 className="font-bold"> {info.title}</h3>
                            </div>
                            <p className="">{info.details}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}