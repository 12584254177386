import { useEffect, useState, useRef, useMemo } from 'react';
import { useHoverHandlers } from "../../components/hover-handler";
import { useNavigate } from 'react-router-dom';

export default function Home() {
    const [yearText, setYearText] = useState('years');
    const [hovered, setHovered] = useState(false);
    const { handleMouseEnter, handleMouseLeave } = useHoverHandlers();
    const indexRef = useRef(0);
    const interval = useRef(null);
    const navigate = useNavigate();

    const arrayOfYears = useMemo(() => [
        '2008s', '2009s', '2010s', '2011s', '2012s', '2013s', '2014s', '2015s', '2016s', 
        '2017s', '2018s', '2019s', '2020s', '2021s', '2022s', '2023s', '2024s'
    ], []);
    
    const handleParagraphEnter = () => {
        setHovered(true);
        setYearText(arrayOfYears[0])
    }

    const handleParagraphLeave = () => {
        setHovered(false);
        setYearText('years');
    }

    useEffect(() => {
        if(hovered){
            interval.current = setInterval(() => {
                setYearText(arrayOfYears[indexRef.current]);
                indexRef.current = (indexRef.current + 1) % arrayOfYears.length;
            }, 100)

            return () => clearInterval(interval.current)
        }
    }, [hovered, arrayOfYears])

    const navigateContact = () => {
        navigate('/contact/')
    }
    
    return(
        <div className="h-screen flex justify-center items-center w-4/5 m-auto animate-fadeInTop mix-blend-difference">
            <h1 
                className="text-7xl tracking-wide mobile:text-xl sm:text-3xl md:text-5xl lg:text-6xl text-center font-bold hover:text-gray-500 transition-all duration-300 ease-in-out z-10"
                onMouseEnter={handleParagraphEnter}
                onMouseLeave={handleParagraphLeave}
                >
                    Hi, I'm <span className='text-white' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>Jerryvel</span>. I'm a <span className='text-white' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>web developer </span> 
                    and <span className='text-white' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>graphic designer</span> with a knack for creating <span className='text-white' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>websites</span> and <span className='text-white' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>logos</span>. 
                    Interested in working together? 
                    Feel free to get in touch—<span className='text-white underline cursor-pointer' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={navigateContact}>CONTACT</span>!
            </h1>
        </div>
    )
}