import { Link } from "react-router-dom"
import { useHoverHandlers } from "../components/hover-handler";

const links = [
    {
        name: 'HOME',
        href: '/'
    },
    {
        name: 'ABOUT',
        href: '/about/'
    },
    {
        name: 'SERVICES',
        href: '/services/'
    },
    {
        name: 'CONTACT',
        href: '/contact/'
    }
]

export default function NavLinks() {
    const { handleMouseEnter, handleMouseLeave } = useHoverHandlers();

    return (
        <>
            {links.map((link, index) => {
                return (
                    <Link 
                        key={index}
                        to={link.href}
                        className="flex gap-5 hover:animate-navAnimation"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <p>{link.name}</p>
                    </Link>
                )
            })}
        </>
    )
}