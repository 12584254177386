import ContactPhrase from "../../components/contact-phrase"
import RecentProject from "./components/recent-project"
import ServicesOffered from "./components/services-offered"
import WorkProcess from "./components/work-process"

export default function Services() {
    return(
        <div className="w-4/5 m-auto">
            <section className="h-auto md:h-screen md:mt-0 mobile:mt-72 flex justify-center items-center">
                <p className="text-bigL mobile:text-6xl sm:text-7xl md:text-8xl lg:text-9xl 2xl:text-bigS font-bold">SERVICES</p>
            </section>
            <section className="h-auto md:mt-0 mt-64 mb-64 flex flex-col justify-center items-start">
                <p className="text-sm">WHAT I BRING TO THE TABLE</p>
                <p className="text-5xl mobile:text-base sm:text-lg md:text-2xl lg:text-3xl leading-tight tracking-wide text-customGray mt-10">
                    I provide a complete suite of services to make your brand stand out. 
                    From stunning website design to seamless development, I cover all your needs. 
                    My focus is on delivering high-quality, tailored solutions that exceed your expectations. 
                    Let me bring your vision to life with innovative and functional web solutions.
                </p>
            </section>
            <ServicesOffered />
            <WorkProcess />
            <RecentProject />
            <ContactPhrase />
        </div>
    )
}