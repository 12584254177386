import { useEffect } from "react";

export default function NotFoundPage() {
    useEffect(() => {
        document.title = '404 __JERRYVEL';
    }, []);

    return (
        <div className="w-4/5  md:ml-[40%] m-auto">
            <div className="h-screen flex flex-col justify-center">
                <div>
                    <h2 className="text-bigL mobile:text-6xl sm:text-7xl md:text-8xl lg:text-9xl 2xl:text-bigS font-bold">OOPS!</h2>
                    <div>
                        <p className="text-2xl mobile:text-sm md:text-xl">We can't seem to find the page you're looking for.</p>
                        <p className="text-xs">Error Code: 404</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
