import { Link } from "react-router-dom";
import { useHoverHandlers } from "./hover-handler";

export default function SideFooter() {
    const { handleMouseEnter, handleMouseLeave } = useHoverHandlers();
    return (
        <aside className="fixed right-0 top-0 lg:w-[10%] md:w-[15%] h-screen flex flex-col justify-between items-center text-right backdrop-blur z-10 md:flex mobile:hidden">
            <div className="flex justify-center items-center flex-1">
                <Link
                    to='/'
                    className="px-5"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <h1 className="text-sm">JERRYVEL</h1>
                    <h2 className="text-sm">CABAÑERO</h2>
                    <p className="text-customGray text-xs">V - 001</p>
                </Link>
            </div>
            <div className="absolute bottom-0 flex flex-col py-4 md:text-xs text-customGray">
                <a onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave} 
                    href='https://www.facebook.com/cabanerojheb/'
                    target="_blank"
                    rel="noreferrer">FACEBOOK</a>
                <a onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave} 
                    href='https://www.linkedin.com/in/cabanerojerryvel/'
                    target="_blank"
                    rel="noreferrer">LINKEDIN</a>
                <a onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave} 
                    href='mailto:cabanero.jerryvel@gmail.com'
                    target="_blank"
                    rel="noreferrer">EMAIL</a>
            </div>
        </aside>
    );
}
