import { useState } from 'react';
import { useHoverHandlers } from '../../../components/hover-handler';

const skills = [
    {
        skill: 'FRONT-END',
        tools: ['JavaScript', 'HTML', 'CSS', 'ReactJS', 'TailwindCSS'],
        learn: ['AngularJS', 'SASS/LESS']
    },
    {
        skill: 'BACK-END',
        tools: ['PHP', 'NodeJS', 'ExpressJS'],
        learn: ['Python', 'Java']
    },
    {
        skill: 'DATABASE',
        tools: ['Firebase', 'MySQL'],
        learn: ['MongoDB', 'PostgreSQL'],
    },
    {
        skill: 'DESIGN',
        tools: ['Figma', 'Adobe Photoshop'],
        learn: ['Illustrator', 'Indesign', 'XD'],
    }
];

export default function ExperienceSection() {
    const { handleMouseEnter, handleMouseLeave } = useHoverHandlers();
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [isHovering, setIsHovering] = useState(false);

    return (
        <div className="m-auto tracking-widest text-customGray relative h-screen mobile:h-auto flex flex-col justify-center items-end">
           <div className='flex flex-col justify-center items-end'>
                <p className='text-white text-xs tracking-widest'>TOOLBOX</p>
           </div>
            <section className="flex">
                <div className="flex flex-row w-full h-auto">
                    <div 
                        className={`absolute pointer-events-none text-xl mobile:text-sm lg:text-lg text-white top-0 left-0 w-1/2 h-full z-50 flex flex-col gap-3 justify-center items-center text-center p-4 bg-black rounded-2xl bg-opacity-85 transition-opacity duration-500 ${isHovering ? 'opacity-100' : 'opacity-0'}`}
                    >
                        {hoveredSkill !== null && skills[hoveredSkill] && (
                            <>
                                <div className="flex flex-col gap-1">
                                    {skills[hoveredSkill].tools.map((tool, index) => (
                                        <span key={index}>{tool}</span>
                                    ))}
                                </div>
                                {skills[hoveredSkill].learn.length > 0 && (
                                    <div className="flex flex-col gap-1 border border-customGray rounded-full">
                                        <p className=" flex items-center bg-customGray text-mainColor rounded-full p-1">EXPLORING</p>
                                        <div className="flex flex-col gap-1 p-1">
                                            {skills[hoveredSkill].learn.map((tool, index) => (
                                                <span key={index}>{tool}</span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className='flex-1 z-0'>
                        {skills.map((unit, index) => (
                            <div key={index} className={`flex flex-col justify-center items-end ${index === 0 ? '' : 'mt-5 mobile:mt-2'}`}>
                                <p className="text-9xl mobile:text-4xl sm:text-5xl md:text-6xl lg:text-8xl hover:text-white duration-300 transition-all ease-in-out"
                                    onMouseEnter={(event) => {
                                        handleMouseEnter(event);
                                        setHoveredSkill(index);
                                        setIsHovering(true); 
                                    }}
                                    onMouseLeave={(event) => {
                                        handleMouseLeave(event);
                                        setIsHovering(false);  
                                        setHoveredSkill(null); 
                                    }}>
                                    {unit.skill}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}
