import { useHover } from './hover-provider';

export const useHoverHandlers = () => {
  const { setHoveredElement } = useHover();

  const handleMouseEnter = (e) => {
    const { width, height, left, top } = e.target.getBoundingClientRect();
    setHoveredElement({ width, height, left, top, locked: true });
  };

  const handleMouseLeave = () => {
    setHoveredElement(null);
  };

  return { handleMouseEnter, handleMouseLeave };
};
