import { useEffect, useState } from 'react';
import Img1 from '../../../assets/pics-related-to-me/1DX21558 copy.jpg';
import Img2 from '../../../assets/pics-related-to-me/234190005_353545846343879_7946559833402702076_n.jpg';
import Img3 from '../../../assets/pics-related-to-me/271973357_455686856129777_2437392495029489599_n.jpg';
import Img4 from '../../../assets/pics-related-to-me/324905367_712279933750466_627103632023572574_n.jpg';
import Img5 from '../../../assets/pics-related-to-me/441556781_823153375930587_5229016998391311589_n.jpg';
import Img6 from '../../../assets/pics-related-to-me/448375714_2575923695922083_4977992407129817405_n.jpg';
import Img7 from '../../../assets/pics-related-to-me/449530863_480817754340293_6409027367984672664_n.jpg';

export default function AboutHover() {

    return (
        <div className="relative h-screen">
            <div className="flex justify-center items-center mobile:h-auto mobile:mt-72 md:h-screen md:mt-0"
            >
                <h1
                    className="mobile:text-7xl sm:text-8xl md:text-9xl lg:text-bigS font-bold animate-fadeInTop z-10"
                >
                    ABOUT
                </h1>
            </div>
           <div className='z-0'>
                <img 
                    src={Img1} 
                    alt='Graduation Pic'
                    className='absolute top-0 h-56 transform translate-x-1/2 translate-y-5 opacity-0 hover:opacity-100 transition-all duration-300 ease-in-out'
                />
                <img 
                    src={Img2} 
                    alt='Hobby 1'
                    className='absolute top-0 h-80 transform -translate-x-1/2 translate-y-[270px] opacity-0 hover:opacity-100 transition-all duration-300 ease-in-out'
                />
                <img 
                    src={Img3} 
                    alt='Random Pic'
                    className='absolute top-0 h-96 transform translate-x-[1050px] translate-y-[450px] opacity-0 hover:opacity-100 transition-all duration-300 ease-in-out'
                />
                <img 
                    src={Img4} 
                    alt='Random Pic'
                    className='absolute top-0 h-96 transform translate-x-[725px] translate-y-[525px] opacity-0 hover:opacity-100 transition-all duration-300 ease-in-out'
                />
                <img 
                    src={Img5} 
                    alt='Flag Football'
                    className='absolute top-0 h-96 transform translate-x-[500px] translate-y-16 opacity-0 hover:opacity-100 transition-all duration-300 ease-in-out'
                />
                <img 
                    src={Img6} 
                    alt='Selfie of me'
                    className='absolute top-0 h-56 transform translate-x-[1000px] translate-y-20 opacity-0 hover:opacity-100 transition-all duration-300 ease-in-out'
                />
                <img 
                    src={Img7} 
                    alt='Hiking'
                    className='absolute top-0 h-96 transform translate-x-[180px] translate-y-[500px] opacity-0 hover:opacity-100 transition-all duration-300 ease-in-out'
                />
           </div>
        </div>
    );
}
