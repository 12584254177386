import { useState, useEffect } from "react";
import usePageLocation from "./page-location";

export default function BlackLogo() {
    const pathname = usePageLocation();
    const [display, setDisplay] = useState(pathname);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDisplay(pathname);
        }, 800);

        return () => clearTimeout(timer);
    }, [pathname]);

    return (
      <>
        {display === 'HOME' && (
          <div>
            <div className="fixed bottom-0 right-0 w-[550px] h-[550px] mobile:w-[250px] mobile:h-[250px] bg-black animate-rotateSquare z-0"></div>
            <div className="fixed w-[250px] h-[250px] mobile:w-[150px] mobile:h-[150px] transform translate-x-1/2 translate-y-1/2 bg-black animate-rotateSquareCounter z-0"></div>
          </div> 
        )}
        {display === 'ABOUT' && (
          <div className="fixed inset-0 -z-10">
            <div className="absolute bottom-0 left-0 w-[300px] h-[300px] bg-black animate-rotateSquare"></div>
            <div className="absolute top-0 right-0 w-[120px] h-[120px] transform translate-x-1/2 translate-y-1/2 bg-black animate-rotateSquareCounter"></div>
          </div>
        )}
        {display === 'SERVICES' && (
          <div>
            <div className="fixed inset-0 -z-10">
              <div className="absolute bottom-0 left-0 w-[150px] h-[150px] bg-black animate-rotateSquare"></div>
              <div className="absolute top-0 right-0 w-[120px] h-[120px] transform translate-x-1/2 translate-y-1/2 bg-black animate-rotateSquareCounter"></div>
            </div>
          </div>
        )}
        {display === 'CONTACT' && (
          <div>
            <div className="fixed inset-0 -z-10 flex items-end justify-center">
                <div className="w-[250px] h-[250px] bg-black animate-rotateSquare"></div>
            </div>

          </div>
        )}
      </>
    );
}
