import { motion } from "framer-motion";

export default function Transition({ children }) {

    const anim = (variants) => {
        return {
            initial: 'initial',
            animate: 'enter',
            exit: 'exit',
            variants
        }
    }

    const slide = {
        initial: {
            top: '100vh'
        },
        enter: {
            top: '100vh'
        },
        exit: {
            top: '0',
            transition: {
                duration: 0.8,
                ease: [0.43, 0.13, 0.23, 0.96]
            }
        }
    }

    const slideOut = {
        initial: {
            top: '0'
        },
        enter: {
            top: '100vh',
            transition: {
                duration: 1,
                ease: [0.43, 0.13, 0.23, 0.96]
            }
        },
        exit: {
            top: '100vh'
        }
    }

    return (
        <div>
            {children}
            <motion.div
                {...anim(slide)}
                className="fixed top-0 left-0 bg-black w-screen h-screen z-10"
            />
            <motion.div
                {...anim(slideOut)}
                className="fixed top-0 left-0 bg-black w-screen h-screen z-10"
            />
        </div>
    );
}
