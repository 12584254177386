import CCRights from "../../components/cc-rights";
import ContactForm from "./components/contact-form";

export default function Contact() {
    return (
        <div className="xl:h-screen mobile:h-auto w-full flex flex-col justify-between">
            <ContactForm/>
            <div className="mb-10 mobile:mt-10">
                <CCRights />
            </div>
        </div>
    )
}