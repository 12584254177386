import { useState, useEffect, useRef } from 'react';
import MarqueeText from "./marquee-text";
import { ReactComponent as Arrow } from '../../../assets/arrow.svg';
import { useHoverHandlers } from '../../../components/hover-handler';
import Img1 from '../../../assets/work-sample/Screenshot_1.jpg';
import Img2 from '../../../assets/work-sample/Screenshot_2.jpg';
import Img3 from '../../../assets/work-sample/Screenshot_3.jpg';
import Img4 from '../../../assets/work-sample/Screenshot_4.jpg';
import Img5 from '../../../assets/work-sample/Screenshot_5.jpg';
import Img6 from '../../../assets/work-sample/Screenshot_6.jpg';
import emailjs, { send } from '@emailjs/browser';
import { PUBLIC_KEY, SERVICE_ID, TEMPLATE_ID } from '../../../constant';



const services = [
    {
        name: 'Website Design',
        alt: 'Stunning Designs',
        description: 'Crafting visually stunning, user-friendly websites that leave a lasting impression.',
    },
    {
        name: 'Website Building',
        alt: 'Perfect Sites',
        description: 'Transforming your vision into a fully functional, responsive website tailored to your needs.'
    },
]

export default function ContactForm(){
    const { handleMouseEnter, handleMouseLeave} = useHoverHandlers();
    const [hoveredService, setHoveredService] = useState(false);
    const [isHovered, setIsHovered] = useState({
        hover: false,
        whatService: null,
    });
    const [values, setValues] = useState({
        email: '',
        name: '',
        concern: '',
    });
    const [buttonText, setButtonText] = useState(`Let's talk`);
    const [lastSubmission, setLastSubmission] = useState(null);
    const form = useRef();

    const imgArray = [Img1, Img2, Img3, Img4, Img5, Img6];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        let interval;
        if (isHovered.hover) {
            interval = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imgArray.length);
            }, 1000); 
        } else {
            setCurrentImageIndex(0); 
        }
        return () => clearInterval(interval); 
    }, [isHovered.hover]);


    const handleMouseEnterChange = (index, e) => {
        setHoveredService(index);
        handleMouseEnter(e);
        setIsHovered({
            hover: true,
            whatService: index
        })
    };

    const handleMouseLeaveChange = () => {
        setHoveredService(null);
        handleMouseLeave();
        setIsHovered({
            hover: false,
            whatService: null
        })
    };

    const downloadResume = () => {
        window.open('https://drive.google.com/file/d/1aziraMaOiGDtbLwed-TtiS1WfGQNSm7E/view?usp=sharing', '_blank')
    }

    const handleFormSubmission = (e) => {
        e.preventDefault();

        const currentTime = new Date().getTime();

        if(lastSubmission && currentTime - lastSubmission < 60000){
            setButtonText('Please wait...');
            return;
        }
       

        emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
        .then(
            () => {
                setButtonText('On its way!');
                setLastSubmission(currentTime);
                setTimeout(() => {
                    setButtonText(`Let's talk`);
                }, 60000)
            },
            (error) => {
                console.log('FAILED...', error);
            },
        );
    }


    return (
        <div className="flex flex-col ">
            <div className='mt-24 md:mt-0'>
                <MarqueeText text={'SAY HI!'} anim={'animate-marquee'}/>
            </div>
            <div className='flex mobile:flex-col xl:flex-row items-center justify-center gap-5 w-4/5 mt-36 m-auto'>
                <div className="xl:w-1/2 mobile:w-full mobile:p-2 tracking-tighter">
                    <p className="text-3xl mb-5">Got a project or an idea you’re passionate about? Let’s collaborate and bring it to life! I’m here to help make magic happen, so don’t hesitate to slide into my inbox at any time. Let’s create something extraordinary together!</p>
                    <p className='mb-10 text-3xl'>Explore my journey—grab my <span onClick={downloadResume} className='cursor-pointer underline'>Resume</span>.</p>
                    <div>
                    {services.map((ser, index) => (
                        <div
                            key={index}
                            className={`relative transition-all duration-300 ease-in-out`}
                            onMouseEnter={(e) => handleMouseEnterChange(index, e)}
                            onMouseLeave={handleMouseLeaveChange}
                        >
                            <div
                                className={`absolute inset-0 flex flex-col transition-opacity duration-300 ease-in-out ${
                                    hoveredService === index ? 'opacity-100' : 'opacity-0'
                                }`}
                            >
                                <p className="text-7xl mobile:text-4xl sm:text-5xl">{ser.alt}</p>
                                <p className="text-xl mt-2 mobile:text-base bg-black z-10 px-3 py-2">{ser.description}</p>
                            </div>
                            <div
                                className={`relative flex-col text-7xl mobile:text-4xl sm:text-5xl transition-opacity duration-300 ease-in-out ${
                                    hoveredService === index ? 'opacity-0' : 'opacity-100'
                                }`}
                            >
                                <p>{ser.name}</p>
                            </div>
                        </div>
                    ))}

                    </div>
                </div>
                <div className='xl:w-1/2 mobile:w-full mobile:p-2 flex flex-col justify-center items-center'>
                    <form ref={form} onSubmit={handleFormSubmission}>
                            <div className='h-96'>
                                <input 
                                    name='name'
                                    className='bg-transparent border-b border-b-white py-3 w-full text-3xl focus:outline-none' 
                                    placeholder='Your name here'
                                    required
                                    onChange={(e) => {setValues({...values, name: e.target.value}); setButtonText(`Let's talk`);}}
                                />
                                <input
                                    name='email' 
                                    className='bg-transparent mt-5 border-b border-b-white py-3 w-full text-3xl focus:outline-none' 
                                    placeholder='Your email here'
                                    required
                                    onChange={(e) => {setValues({...values, email:e.target.value}); setButtonText(`Let's talk`);}}
                                />
                                <textarea
                                    rows={5}
                                    name='message'
                                    className='bg-transparent border-b border-b-white pt-5 w-full py-3 text-3xl mt-5 focus:outline-none'
                                    placeholder='Your concern here'
                                    required
                                    onChange={(e) => {setValues({...values, concern: e.target.value}); setButtonText(`Let's talk`);}}
                                />
                                <button
                                    type="submit"
                                    value="Send"
                                    className={`border border-white w-full text-3xl px-10 py-5 text-white mt-4 transition-all duration-300 ease-in-out
                                        ${buttonText === 'On its way!' ? ' cursor-not-allowed' : 'hover:bg-white hover:text-mainColor'}
                                    `}
                                    disabled={buttonText === 'On its way!'}
                                    >
                                        <div className="flex justify-between">
                                            <p>{buttonText}</p>
                                            <Arrow className={`w-8 h-8 ${buttonText === 'On its way!' ? '' : 'hover:bg-black'}`} />
                                        </div>
                                </button>

                            </div>
                        </form>
                </div>
            </div>
        </div>
    )
}