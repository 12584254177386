import ExperienceSection from "./components/experience-section";
import ContactPhrase from "../../components/contact-phrase";
import AboutHover from "./components/about-hover";

export default function About() {
    return(
        <div className="mobile:w-4/5 m-auto">
            <AboutHover />
            <div className="mobile:p-2">
                <div className="m-auto h-screen flex flex-col justify-center items-start">
                    <p className="text-sm text-white">FEW THINGS ABOUT ME</p>
                    <p className="text-5xl mobile:text-base sm:text-lg md:text-2xl lg:text-3xl mt-10 leading-tight tracking-wide text-customGray">
                        I'm a filipino web developer from Cebu City, 
                        fresh out of a Computer Engineering program. My love for web development started in school, 
                        where I specialized in creating dynamic web applications. Outside work, I enjoy karaoke, hitting the gym, 
                        and playing flag football.
                    </p>
                    <p className="text-5xl mobile:text-base sm:text-lg md:text-2xl lg:text-3xl mt-10 leading-tight tracking-wide text-customGray">
                        In college, I freelanced in photo editing and graphic design, honing my creative skills. 
                        Now, in my current role, I maintain our website's smooth operation. 
                        Combining technical expertise with creativity, I’m committed to delivering excellent results for my clients.
                    </p>
                </div>
                <ExperienceSection />
                <ContactPhrase/>
            </div>
        </div>
    )
}