import { Link } from "react-router-dom";
import { useHoverHandlers } from "./hover-handler";

export default function CCRights(){
    const { handleMouseEnter, handleMouseLeave } = useHoverHandlers();

    return(
        <div className="text-xs flex flex-col justify-center items-center mt-20 text-customGray">
            <p>2024 &copy; __JERRYVEL</p>
            <Link
                to={'/privacy-policy/'}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >Privacy Policy</Link>
        </div>
    )
}