import NavLinks from "./nav-links";
import { useEffect, useState } from 'react';
import usePageLocation from "./page-location";

export default function SideNav() {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [ openNav, setOpenNav] = useState(false);

    const title = usePageLocation();

    useEffect(() => {
        document.title = title + ' __JERRYVEL';
        setScrollHeight(0);
        window.scrollTo(0, 0)
    }, [title])


    useEffect(() => {
        const handleScroll = () => {
            const maxScrollHeight = 500;
            const totalScrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
            const newScrollHeight = Math.min((window.scrollY / totalScrollableHeight) * maxScrollHeight, maxScrollHeight);
            setScrollHeight(newScrollHeight);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
       <div>
            <aside className="fixed h-full lg:w-[10%] md:w-[15%] border-r-2 border-r-white flex flex-col min-h-screen backdrop-blur z-20 md:flex mobile:hidden">
                <div className="flex flex-row justify-center items-center flex-1 gap-5">
                    <div className="flex flex-col items-end">
                        <div className="border-r-2 border-r-mainColor" style={{ height: `${scrollHeight}px` }}></div>
                        <div className="border-r-2 border-r-white" style={{ height: `${500 - scrollHeight}px` }}></div>
                    </div>
                    <div className="md:text-sm">
                        <NavLinks/>
                    </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 text-center p-4 text-customGray">
                    <div className="text-sm md:text-xs">
                        AVAILABLE <br/> FOR WORK
                    </div>
                </div>
            </aside>

            <nav className="fixed justify-between items-center px-2 py-5 backdrop-blur-md w-full z-30 md:hidden mobile:flex">
                <div className="p-1 h-16 gap-1 w-16 flex flex-col justify-center items-end border border-white"
                    onClick={() => setOpenNav(!openNav)}
                >
                    <div className={`w-full ${openNav ? 'h-2' : 'h-1'} transition-all duration-200 ease-in-out bg-white`}></div>
                    <div className={`w-full ${openNav ? 'h-2' : 'h-5'} transition-all duration-200 ease-in-out bg-white`}></div>
                </div>
                <div className="flex flex-col items-end text-sm">
                    <h2>JERRYVEL</h2>
                    <h2>CABAÑERO</h2>
                    <p className="text-customGray">V - 001</p>
                </div>
            </nav>
            <div className={`px-2 fixed inset-0 h-screen transition-all duration-500 z-20 ease-in-out ${openNav ? 'w-full text-6xl' : 'w-0 opacity-0 pointer-events-none'} bg-black z-10`}>
                <div className="flex gap-52 flex-col justify-center h-screen font-bold">
                    <div onClick={() => setOpenNav(!openNav)}>
                        <NavLinks/>
                    </div>
                    <div>
                        <p className="text-sm font-thin text-customGray">AVAILABLE FOR WORK</p>
                        <p className="text-sm font-thin text-customGray">cabanero.jerryvel@gmail.com</p>
                    </div>
                </div>
            </div>
       </div>
    );
}
