import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useHover } from './hover-provider';

export default function CustomMouse() {
  const { hoveredElement } = useHover();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className='mobile:hidden md:flex'>
      <motion.div 
        className='border fixed top-0 left-0 pointer-events-none z-50'
        style={{
          x: hoveredElement && hoveredElement.locked ? hoveredElement.left : mousePosition.x - (hoveredElement ? hoveredElement.width / 2 : 16),
          y: hoveredElement && hoveredElement.locked ? hoveredElement.top : mousePosition.y - (hoveredElement ? hoveredElement.height / 2 : 16),
          width: hoveredElement ? hoveredElement.width : 32,
          height: hoveredElement ? hoveredElement.height : 32,
          transition: 'transform 0.1s, width 0.3s, height 0.3s',
        }}
      />
      <motion.div
        className="h-3 w-3 bg-white rounded-full fixed top-0 left-0 pointer-events-none bg-blend-overlay z-50"
        style={{
          x: mousePosition.x - 6,
          y: mousePosition.y - 6,
          mixBlendMode: 'difference'
        }}
      />
    </div>
  );
}
