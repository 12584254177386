import { useLocation } from 'react-router-dom';

const pathTitles = {
    '/': 'HOME',
    '/about/': 'ABOUT',
    '/services/': 'SERVICES',
    '/contact/': 'CONTACT',
    '/labs/' : 'LABS',
};

const usePageLocation = () => {
    const { pathname } = useLocation();
    return pathTitles[pathname] || '__JERRYVEL';
};

export default usePageLocation;
