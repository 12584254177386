import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import SideNav from './components/side-nav';
import CustomWidth from './components/custom-width';
import SideFooter from './components/side-footer';
import CustomMouse from './components/custom-mouse';
import { HoverProvider } from './components/hover-provider';
import BlackLogo from './components/black-logo';
import AnimatedRoutes from './components/animate-routes';

export default function App() {
  return (
    <HoverProvider>
      <div className='text-white flex font-mainfont overflow-hidden relative'>
      <div
        className="absolute inset-0 h-full w-full bg-mainColor bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)] z-0"
      ></div>
        <div className='z-10'>
          <BrowserRouter>
            <CustomMouse/>
            <BlackLogo/>
            <SideNav/>
            <CustomWidth>
              <AnimatedRoutes/>
            </CustomWidth>
            <SideFooter/>
          </BrowserRouter>
        </div>
      </div>
    </HoverProvider>
  );
}