
export default function MarqueeText({text, anim}) {
  return (
    <div className="relative w-full">
      <div className={`absolute whitespace-nowrap flex ${anim}`}>
        {
          [...Array(20)].map((_, index) => (
            <h1 className="text-9xl">{text}</h1>
          ))
        }
      </div>
    </div>
  );
}
