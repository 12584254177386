import { Link } from "react-router-dom"
import { useHoverHandlers } from "./hover-handler"

export default function ContactPhrase(){
    const { handleMouseEnter, handleMouseLeave } = useHoverHandlers();
    return(
        <div className="flex flex-col justify-center items-center text-center mobile:text-xl sm:text-3xl md:text-5xl lg:text-7xl h-screen m-auto">
            <Link
                to='/contact/'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                Got a project or idea? Let's make magic—slide into my inbox anytime!
            </Link>
            <p className="text-xs text-customGray mobile:flex md:hidden">(Click Me)</p>
        </div>
    )
}