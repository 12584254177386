const services = [
    {
        offer: 'Website Design',
        description: [
            'Responsive design',
            'UI/UX design',
            'Prototyping',
            'User flow creation'
        ],
    },
    {
        offer: 'Website Building',
        description: [
            'Custom website development',
            'E-commerce solutions',
            'Website maintenance',
            'Performance optimization'
        ],
    },
]

export default function ServicesOffered() {
    return (
        <section className="text-customGray tracking-widest flex flex-col gap-10 h-auto mb-64">
            {services.map((key, index) => (
                <div key={index}>
                    <h2 className="text-9xl mobile:text-3xl sm:text-4xl md:text-5xl lg:text-9xl">{key.offer}</h2>
                    <p className="flex flex-col lg:text-2xl mobile:text-base gap-5 mobile:gap-0 mt-5 mobile:mt-2 p-10 mobile:p-2">
                    {key.description.map((desc, index) => (
                        <p key={index} class>{desc}</p>
                    ))}
                    </p>
                </div>
            ))}
        </section>
    )
}