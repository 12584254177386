import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../pages/home/home';
import About from '../pages/about/about';
import Services from '../pages/services/services';
import Contact from '../pages/contact/contact';
import Labs from '../pages/labs/labs';
import NotFoundPage from '../pages/single-page/not-found-page';
import ProvicyPolicy from '../pages/single-page/privacy-policy';

import Transition from './transition';
import { AnimatePresence } from 'framer-motion';

export default function AnimatedRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence mode='wait'>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Transition><Home /></Transition>} />
                <Route path='/about/' element={<Transition><About /></Transition>} />
                <Route path='/services/' element={<Transition><Services /></Transition>}/>
                <Route path='/contact/' element={<Transition><Contact /></Transition>}/>
                <Route path='/privacy-policy/' element={<Transition><ProvicyPolicy /></Transition>}/>
                {/* <Route path='/labs/' element={<Transition><Labs /></Transition>}/> */}
                <Route path='*' element={<Transition><NotFoundPage /></Transition>}/>
            </Routes>
        </AnimatePresence>  
    );
}
