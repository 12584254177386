import MarqueeText from "../../contact/components/marquee-text";
import Arrow from '../../../assets/arrow.svg';
import { useHoverHandlers } from "../../../components/hover-handler";

export default function RecentProject() {
    const { handleMouseEnter, handleMouseLeave } = useHoverHandlers()

    return(
        <section className="h-screen flex flex-col justify-center">
            <h2 className="text-9xl mobile:text-5xl md:text-8xl lg:text-9xl">Work Project</h2>
            <div className="w-full h-52 bg-quilatonBanner bg-cover p-5 flex flex-col justify-center tracking-widest mt-10">
                <p className="text-5xl font-bold mobile:text-xl md:text-3xl">Quilaton Law Office</p>
                <p className="text-xs md:text-base">An all-specialization and independent law firm in Cebu City, Philippines that offers a wide range of legal services.</p>
                <div>
                    <a
                        href="https://www.quilatonlawoffice.com/"
                        target="__blank"
                        rel="noopener"
                        className="inline-flex items-center text-xs md:text-base gap-3 mt-5 border-b border-b-white"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <p>Visit site</p>
                        <img src={Arrow} alt="Arrow" className="w-4 md:w-5 invert"/>
                    </a>
                </div>
            </div>
            <div className="w-full h-24 mt-5 bg-black overflow-hidden"><MarqueeText text='PROJECTS UNDERWAY, UPDATES SOON.' anim={'animate-marqueeHover'}/></div>
        </section>
    )
}