const process = [
    {
        name: 'Research',
        steps: [
            'Define Goals',
            'UI/UX Research',
            'Website Brand Style',
            'One Component Design',
        ]
    },
    {
        name: 'UI/UX Design',
        steps: [
            'Homepage Design',
            'Full Desktop Design',
            'Mobile Ver. Design',
            'Website Prototype',
            'Free Revisions'
        ]
    },
    {
        name: 'Development',
        steps: [
            'Frontend Development',
            'Backend Development',
            'Speed Optimization',
            'Basic SEO Optimization'
        ]
    },
    {
        name: 'Delivery',
        steps: [
            'Deploy Hosting',
            'Update & Maintenance',
            'Support',
        ]
    }
]

export default function WorkProcess(){
    return(
        <section className="h-auto flex flex-col justify-center">
            <h2 className="text-9xl mobile:text-7xl lg:text-9xl">Work Process</h2>
            <div className="flex flex-col mt-24">
                <div className="flex flex-row mobile:flex-col lg:flex-row lg:border-b border-b-white mobile:border-0">
                    {process.slice(0,2).map((proc, index) => (
                        <div key={index} className={`flex flex-col p-5 tracking-widest w-1/2 mobile:w-full ${(index + 1) === 2 ? 'lg:border-l border-l-white mobile:border-0' : ''}`}>
                            <p>{"0" + (index + 1)}</p>
                            <h2 className="text-7xl mobile:text-4xl sm:text-5xl">{proc.name}</h2>
                            <div className="p-5 text-l">
                                {proc.steps.map((st, index) => (
                                    <p key={index}>{st}</p>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex lg:flex-row mobile:flex-col">
                    {process.slice(2,4).map((proc, index) => (
                        <div key={index} className={`flex flex-col p-5 tracking-widest w-1/2 mobile:w-full ${(index + 3) === 4 ?  'lg:border-l border-l-white mobile:border-0' : ''}`}>
                            <p>{"0" + (index + 3)}</p>
                            <h2 className="text-7xl mobile:text-4xl">{proc.name}</h2>
                            <div className="p-5 text-l">
                                {proc.steps.map((st, index) => (
                                    <p key={index}>{st}</p>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}